export default {
  legend: [
    {
      color: "#dddc75",
      label: "Parcheggio",
    },
    {
      color: "#dcdcdc",
      label: "Locali tecnologici",
    },
    {
      color: "#fdbe2c",
      label: "Locali di supporto",
    },
    {
      color: "#de83fc",
      label: "Attività sanitaria",
    },
    {
      color: "#0b24FB",
      label: "Collegamenti verticali Interni",
    },
    {
      color: "#fc411D",
      label: "Collegamenti verticali Esterni",
    },
    {
      color: "#71d1cb",
      label: "Copertura",
    },
    {
      color: "#E5FECB",
      label: "Area verde",
    },
  ],
  treeData: [
    {
      label: "Livello -1",
      id: 3,
      key: 1,
      level: "m1",
      postiLetto: "0",
      mq: "16.970,06",
      children: [
        {
          label: "Modulo A - Parcheggi interrati *",
          id: 0,
        },
        {
          label: "Modulo B - Spogliatoi, Depositi, Sottocentrali e tubazioni *",
          id: 1,
        },
        {
          label: "Modulo C - Parcheggi interrati *",
          id: 2,
        },
        {
          label: "Modulo D - Depositi *",
          id: 3,
        },
        {
          label: "Modulo E - Hall *",
          id: 4,
        },
        {
          label: "Modulo F2 - Farmacia  *",
          id: 8,
        },
        {
          label: "Modulo F1 - Cucina (al grezzo)  *",
          id: 6,
          children: [
            {
              label: "Cucina ( Completa )",
              id: 0,
            },
            {
              label: "Depositi",
              id: 1,
            },
          ],
        },
        {
          label: "Modulo F - Sottocentrali e tubazioni  *",
          id: 5,
        },
        {
          label: "Modulo G - Parcheggi interrati *",
          id: 9,
        },
        {
          label: "Modulo H1 - sterilizzazione (al grezzo) *",
          id: 11,
          children: [
            {
              label: "Sterilizzazione ( Completa )",
              id: 0,
            },
            {
              label: "Depositi",
              id: 1,
            },
          ],
        },
        {
          label: "Modulo H3 - Morgue/preparazione salme *",
          id: 13,
        },
        {
          label: "Modulo H2 - Depositi *",
          id: 12,
        },
        {
          label: "Modulo H - Sottocentrali e tubazioni *",
          id: 10,
        },
        {
          label: "Modulo I - Parcheggi interrati *",
          id: 14,
        },
        {
          label: "Modulo L - Parcheggi interrati *",
          id: 15,
        },
        {
          label: "Modulo M - Parcheggi interrati *",
          id: 16,
        },
        {
          label: "Modulo N, O, P - centrali/isola ecologica/locali manutenzione *",
          id: 17,
        },
      ],

    },
    {
      label: "Livello 0",
      id: 0,
      key: 2,
      level: "0",
      postiLetto: "165",
      mq: "17.667",
      children: [
        {
          label: "Modulo Q - DEA : Pronto soccorso, osservazione breve, terapia intensiva e subintensiva",
          levelId: 0,
          id: 0,
          canBeDisabled: true,
          isEnabled: true,
        },
        {
          label: "Modulo M",
          id: 1,
          canBeDisabled: true,
          isEnabled: true,
          levelId: 0,
          children: [
            {
              label: "Dialisi *",
              id: 1,
              moduleId: 1,
              isChecked: true,
              isDefault: true,
            },
            {
              label: "Uffici amministrativi",
              id: 1,
              moduleId: 1,
              isChecked: false,
              isDefault: false,
            },
            {
              label: "Degenza 20 Posti letto",
              id: 1,
              moduleId: 1,
              isChecked: false,
              isDefault: false,
            }
          ]
        },
        {
          label: "Modulo L",
          id: 3,
          canBeDisabled: true,
          isEnabled: true,
          levelId: 0,
          children: [
            {
              label: "Psichiatria *",
              id: 1,
              moduleId: 3,
              isChecked: true,
              isDefault: true,
            },
            {
              label: "Studi Medici",
              id: 1,
              moduleId: 3,
              isChecked: false,
              isDefault: false,
            },
            {
              label: "Degenza 20 Posti letto",
              id: 1,
              moduleId: 3,
              isChecked: false,
              isDefault: false,
            },
            {
              label: "Sala conferenze",
              id: 1,
              moduleId: 3,
              isChecked: false,
              isDefault: false,
            }]
        },
        {
          label: "Modulo F",
          id: 4,
          levelId: 0,
          children: [
            {
              label: "Diagnostica per immagine *",
              id: 1,
              moduleId: 4,
              isChecked: true,
              isDefault: true,
            },
            {
              label: "Fisiochinesiterapia + palestre",
              id: 1,
              moduleId: 4,
              isChecked: false,
              isDefault: false,
            },
          ]
        },
        {
          label: "Modulo G",
          id: 5,
          levelId: 0,
          children: [
            {
              label: "Medicina Nucleare *",
              id: 1,
              moduleId: 5,
              isChecked: true,
              isDefault: true,
            },
            {
              label: "Ambulatori Specialistici",
              id: 1,
              moduleId: 5,
              isChecked: false,
              isDefault: false,
            },
            {
              label: "Mensa / Bar ristorante",
              id: 1,
              moduleId: 5,
              isChecked: false,
              isDefault: false,
            },
            {
              label: "Diagnostica per immagine 4 SALE",
              id: 1,
              moduleId: 5,
              isChecked: false,
              isDefault: false,
            },
          ]
        },
        {
          label: "Modulo H1 + H2",
          id: 6,
          levelId: 0,
          children: [
            {
              label: "Blocco operatorio 8 sale *",
              id: 1,
              moduleId: 6,
              isChecked: true,
              isDefault: true,
            },
            {
              label: "Fisiochinesiterapia + palestre",
              id: 1,
              moduleId: 6,
              isChecked: false,
              isDefault: false,
            },
            {
              label: "( H1 ) - Blocco operatorio 4 SALE",
              id: 1,
              moduleId: 6,
              isChecked: false,
              isDefault: false,
            },
            {
              label: "( H2 ) - Diagnostica per immagine 4 SALE",
              id: 1,
              moduleId: 6,
              isChecked: false,
              isDefault: false,
            },
          ]
        },
        {
          label: "Modulo I",
          id: 7,
          levelId: 0,
          children: [
            {
              label: "Interventistica ( ambulatorio chirurgico ) *",
              id: 1,
              moduleId: 7,
              isChecked: true,
              isDefault: true,
            },
            {
              label: "Mensa / Bar ristorante",
              id: 1,
              moduleId: 7,
              isChecked: false,
              isDefault: false,
            },
            {
              label: "Fisiochinesiterapia + palestre",
              id: 1,
              moduleId: 7,
              isChecked: false,
              isDefault: false,
            },
            {
              label: "Studi medici",
              id: 1,
              moduleId: 7,
              isChecked: false,
              isDefault: false,
            },
            {
              label: "Ambulatori specialistici",
              id: 1,
              moduleId: 7,
              isChecked: false,
              isDefault: false,
            },
          ]
        },
        {
          label: "Modulo C",
          id: 8,
          levelId: 0,
          children: [
            {
              label: "Ambulatori specialistici *",
              id: 1,
              moduleId: 8,
              isChecked: true,
              isDefault: true,
            },
            {
              label: "Degenza 20 Posti letto",
              id: 1,
              moduleId: 8,
              isChecked: false,
              isDefault: false,
            },
          ]
        },
        {
          label: "Modulo B - Ambulatori chirurgici / endoscopia , Day surgery",
          id: 9,
          levelId: 0,
        },
        {
          label: "Modulo A",
          id: 11,
          levelId: 0,
          children: [
            {
              label: "Day service *",
              id: 1,
              moduleId: 11,
              isChecked: true,
              isDefault: true,
            },
            {
              label: "Degenza 20 Posti letto",
              id: 1,
              moduleId: 11,
              isChecked: false,
              isDefault: false,
            },
          ]
        },
        {
          label: "Modulo E - Hall / reception",
          id: 12,
          levelId: 0,
        },
        {
          label: "Modulo D - Culto, Attese, Servizi generali / Bar ristorante",
          id: 13,
          levelId: 0,
        },
      ],
    },
    {
      label: "Livello 1",
      key: 3,
      level: "1",
      postiLetto: "41",
      mq: "4.138,00",
      children: [
        {
          label: "Modulo  E - Hall",
          id: 0,
        },
        {
          label: "Modulo  D - Servizi generali / caffetteria",
          id: 0,
        },
        {
          label: "Modulo B - Blocco parto + Neonatologia",
          id: 1,
          children: [
            {
              label: "Uffici amministrativi",
              id: 0,
            },
            {
              label: "Degenza 20 Posti letto",
              id: 0,
            },
          ]
        },
        {
          label: "Modulo A - Ostetricia + Degenza",
          id: 1,
          children: [
            {
              label: "Studi medici",
              id: 0,
            },
            {
              label: "Degenza 20 Posti letto",
              id: 0,
            },
          ]
        },
        {
          label: "Modulo C - Pediatria / Degenza",
          id: 1,
          children: [
            {
              label: "Mensa / Bar ristorante",
              id: 0,
            },
            {
              label: "Degenza 20 Posti letto",
              id: 0,
            },
            {
              label: "Studi medici",
              id: 0,
            },
          ]
        },
      ],
    },
    {
      label: "Livello 2",
      key: 4,
      level: "2",
      postiLetto: "114",
      mq: "4.138,00",
      children: [
        {
          label: "Modulo E - Hall + connettivo",
          id: 0,
        },
        {
          label: "Modulo D - Supporto degenza + Attese",
          id: 1,
        },
        {
          label: "Modulo A - Degenza 38 Posti letto",
          id: 2,
        },
        {
          label: "Modulo B - Degenza 38 Posti letto",
          id: 2,
        },
        {
          label: "Modulo C - Degenza 38 Posti letto",
          id: 2,
        },
      ],
    },
    {
      label: "Livello 3",
      key: 5,
      level: "3",
      postiLetto: "114",
      mq: "4.138,00",
      children: [
        {
          label: "Modulo E - Hall + connettivo",
          id: 0,
        },
        {
          label: "Modulo D - Supporto degenza + Attese",
          id: 1,
        },
        {
          label: "Modulo A - Degenza 38 Posti letto",
          id: 2,
        },
        {
          label: "Modulo B - Degenza 38 Posti letto",
          id: 2,
        },
        {
          label: "Modulo C - Degenza 38 Posti letto",
          id: 2,
        },
      ],
    },
    {
      label: "Livello 4 - Ultimo piano",
      key: 6,
      level: "4",
      postiLetto: "",
      mq: "1.715,00",
      canBeDisabled: true,
      isEnabled: true,
      children: [
        {
          label: "Modulo E - Hall + connettivo",
          id: 0,
        },
        {
          label: "Modulo B - Amministrazione / Sottocentrali",
          id: 1,
        },
        {
          label: "Modulo A - Studi medici / Sottocentrali",
          id: 2,
          children: [
            {
              label: "Mensa / Ristorazione",
              id: 2,
            }
          ]
        },
        {
          label: "Modulo C - Studi medici / Sottocentrali",
          id: 2,
        },
      ],
    },
    {
      label: "Aree esterne",
      id: 2,
      key: 7,
      level: "0",
      children: [
        {
          label: "Modulo R1 - Parcheggio (L0)",
          id: 2,
          canBeDisabled: true,
          isEnabled: true,
        },
        {
          label: "Modulo R2 - Parcheggio (L0)",
          id: 2,
          canBeDisabled: true,
          isEnabled: true,
        },
        {
          label: "Modulo R3 - Parcheggio (L0)",
          id: 2,
          canBeDisabled: true,
          isEnabled: true,
        },
        {
          label: "Modulo R4 - Elisuperficie (L0)",
          id: 2,
          canBeDisabled: true,
          isEnabled: true,
        }
      ]
    },
  ],
  treeDataSummary: [
    {
      label: "Livello -1",
      id: "livello_-1",
      postiLetto: "0",
      mq: "16.970,06",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
    },
    {
      label: "Livello 0",
      id: "livello_0",
      postiLetto: "165",
      mq: "17.667",
      description: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)."
    },
    {
      label: "Livello 1",
      id: "livello_1",
      postiLetto: "41",
      mq: "4.138,00",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
    },
    {
      label: "Livello 2",
      id: "livello_2",
      postiLetto: "114",
      mq: "4.138,00",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
    },
    {
      label: "Livello 3",
      id: "livello_3",
      postiLetto: "114",
      mq: "4.138,00",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
    },
    {
      label: "Livello 4",
      id: "livello_4",
      postiLetto: "0",
      mq: "1.715,00",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
    },
    {
      label: "Aree Esterne",
      id: "aree_esterne",
      postiLetto: "0",
      mq: "-",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
    },
  ],
  treeDefaultProps: {
    children: "children",
    label: "label",
  },
  treeDataReport: [
    {
      label: "1. Dati geometrici",
      componentName: "GeometricsReport",
      reportUrl: "/report/R_DatiGeometrici.pdf",
      id: 1,
      projectId: 1,
    },
    {
      label: "2. Dati parametrici",
      componentName: "ParametricsReport",
      reportUrl: "/report/R_DatiParametrici.pdf",
      id: 3,
      projectId: 1,
    },
    {
      label: "3. Parcheggi",
      componentName: "ParkingLotsReport",
      reportUrl: "/report/R_Parcheggi.pdf",
      id: 4,
      projectId: 1,
    },
    {
      label: "4. Portate impianti",
      componentName: "RangeSystemsReport",
      reportUrl: "/report/R_PortateImpianti.pdf",
      id: 5,
      projectId: 1,
    },
    {
      label: "5. Consistenza produzione",
      componentName: "ConsistencyReport",
      reportUrl: "/report/R_ConsistenzaProduzione.pdf",
      id: 6,
      projectId: 1,
    },
    {
      label: "6. Posti letto",
      componentName: "BedsReport",
      reportUrl: "/report/R_PostiLetto.pdf",
      id: 7,
      projectId: 1,
    },
    {
      label: "7. Costi",
      componentName: "CostsReport",
      reportUrl: "/report/R_Costi.pdf",
      id: 0,
      projectId: 1,
    },
  ],
  resumeData: {
    livello_meno1: [
      {
        module: "Modulo A",
        destination: "Parcheggi interrati",
        mq: "1.969,50",
        postiLetto: null,
        consistency: 71,
        note: "stalli",
        presence: true,
      },
      {
        module: "Modulo B",
        destination: "Spogiatoi",
        mq: "661,00",
        postiLetto: null,
        consistency: null,
        note: null,
        presence: true,
      },
      {
        module: "Modulo B",
        destination: "Depositi",
        mq: "383,70",
        postiLetto: null,
        consistency: null,
        note: null,
        presence: true,
      },
      {
        module: "Modulo C",
        destination: "Parcheggi interrati",
        mq: "1.969,50",
        postiLetto: null,
        consistency: 71,
        note: "stalli",
        presence: true,
      },
      {
        module: "Modulo D",
        destination: "Depositi",
        mq: "175,30",
        postiLetto: null,
        consistency: null,
        note: null,
        presence: true,
      },
      {
        module: "Modulo E",
        destination: "Hall",
        mq: "227,00",
        postiLetto: null,
        consistency: null,
        note: null,
        presence: true,
      },
      {
        module: "Modulo F2",
        destination: "Farmacia",
        mq: "132,00",
        postiLetto: null,
        consistency: null,
        note: null,
        presence: true,
      },
      {
        module: "Modulo F1",
        destination: "Cucina (al grezzo)",
        mq: "352,00",
        postiLetto: null,
        consistency: null,
        note: null,
        presence: true,
      },
      {
        module: "Modulo G",
        destination: "Parcheggi interrati",
        mq: "1046,00",
        postiLetto: null,
        consistency: 28,
        note: "stalli",
        presence: true,
      },
      {
        module: "Modulo H1",
        destination: "sterilizzazione (al grezzo)",
        mq: "352,00",
        postiLetto: null,
        consistency: null,
        note: null,
        presence: true,
      },
      {
        module: "Modulo H3",
        destination: "Morgue/preparazione salme",
        mq: "132,00",
        postiLetto: null,
        consistency: null,
        note: null,
        presence: true,
      },
      {
        module: "Modulo H2",
        destination: "Depositi",
        mq: "323,00",
        postiLetto: null,
        consistency: null,
        note: null,
        presence: true,
      },
      {
        module: "Modulo I",
        destination: "Parcheggi interrati",
        mq: "1046,00",
        postiLetto: null,
        consistency: 28,
        note: "stalli",
        presence: true,
      },
      {
        module: "Modulo L",
        destination: "Parcheggi interrati",
        mq: "674,00",
        postiLetto: null,
        consistency: 29,
        note: "stalli",
        presence: true,
      },
      {
        module: "Modulo M",
        destination: "Parcheggi interrati",
        mq: "674,00",
        postiLetto: null,
        consistency: 29,
        note: "stalli",
        presence: true,
      },
      {
        module: "Modulo N-O-P",
        destination: "centrali/isola ecologica/locali manutenzione",
        mq: "4155,00",
        postiLetto: null,
        consistency: null,
        note: null,
        presence: true,
      },

    ],
    livello_0: [
      {
        module: "Modulo Q",
        destination: "Pronto soccorso",
        mq: "2.897,00",
        postiLetto: 37,
        consistency: null,
        note: null,
        presence: true,
      },
      {
        module: "Modulo Q",
        destination: "Diagnostica dedicata",
        mq: 223,
        postiLetto: null,
        consistency: "2 sale",
        note: "n°1 TAC+ n°1 RX",
        presence: true,
      },
      {
        module: "Modulo Q",
        destination: "Terapia intensiva",
        mq: 1883,
        postiLetto: 28,
        consistency: null,
        note: null,
        presence: true,
      },
      {
        module: "Modulo Q",
        destination: "subintensiva + UTIC",
        mq: 1083,
        postiLetto: 16,
        consistency: null,
        note: null,
        presence: true,
      },

      {
        module: "Modulo M",
        destination: "Dialisi",
        mq: 655,
        postiLetto: 24,
        consistency: null,
        note: null,
        presence: true,
      },
      {
        module: "Modulo M",
        destination: "Uffici amministrativi",
        mq: 655,
        postiLetto: null,
        consistency: null,
        note: null,
        presence: false,
      },
      {
        module: "Modulo M",
        destination: "degenza 20pl",
        mq: 655,
        postiLetto: 20,
        consistency: null,
        note: null,
        presence: false,
      },


      {
        module: "Modulo L",
        destination: "Psichiatria",
        mq: 654,
        postiLetto: 16,
        consistency: null,
        note: null,
        presence: true,
      },
      {
        module: "Modulo L",
        destination: "studi medici",
        mq: 654,
        postiLetto: null,
        consistency: null,
        note: null,
        presence: false,
      },
      {
        module: "Modulo L",
        destination: "Degenza 20pl",
        mq: 654,
        postiLetto: 20,
        consistency: null,
        note: null,
        presence: false,
      },
      {
        module: "Modulo L",
        destination: "sala conferenze",
        mq: 654,
        postiLetto: null,
        consistency: null,
        note: null,
        presence: false,
      },

      {
        module: "Modulo F",
        destination: "Diagnostica per immagine",
        mq: 1163,
        postiLetto: null,
        consistency: 9,
        note: "n°2 TAC+n°2 RMN di cui una sola attiva,n°3 RX + n°2 ECO",
        presence: true,
      },
      {
        module: "Modulo F",
        destination: "Fisiochinesiterapia + palestre",
        mq: 1163,
        postiLetto: null,
        consistency: null,
        note: null,
        presence: false,
      },


      {
        module: "Modulo G",
        destination: "Medicina nucleare",
        mq: 406,
        postiLetto: null,
        consistency: 2,
        note: "n°1 gamma camera+ n°1 PET",
        presence: true,
      },
      {
        module: "Modulo G",
        destination: "ambulatori specialistici",
        mq: 406,
        postiLetto: null,
        consistency: null,
        note: null,
        presence: false,
      },
      {
        module: "Modulo G",
        destination: "mensa/bar ristorante",
        mq: 406,
        postiLetto: null,
        consistency: null,
        note: null,
        presence: false,
      },
      {
        module: "Modulo G",
        destination: "diagnostica per immagine 4 SALE",
        mq: 406,
        postiLetto: null,
        consistency: 2,
        note: "n°1 TAC+n°1 RMN, n°1 RX + n°1 ECO",
        presence: false,
      },

      {
        module: "Modulo H1+H2",
        destination: "Blocco operatorio 8 Sale",
        mq: 1137,
        postiLetto: null,
        consistency: 8,
        note: "n°8 sale di cui n°1 per emergenza e n°7 elettive+ 8 pl recovery",
        presence: true,
      },
      {
        module: "Modulo H1+H2",
        destination: "Fisiochinesiterapia + palestre",
        mq: 1137,
        postiLetto: null,
        consistency: 8,
        note: "",
        presence: false,
      },
      {
        module: "Modulo H1+H2",
        destination: "ambulatori specialistici",
        mq: 1137,
        postiLetto: null,
        consistency: null,
        note: null,
        presence: false,
      },
      {
        module: "Modulo H1+H2",
        destination: "solo H1 - blocco operatorio 4 SALE",
        mq: 569,
        postiLetto: 4,
        consistency: 4,
        note: "n°4 sale + 4 pl recovery",
        presence: false,
      },
      {
        module: "Modulo H1+H2",
        destination: "solo H2 - Blocco operatorio 8 Sale",
        mq: 569,
        postiLetto: null,
        consistency: 8,
        note: "n°1 TAC+n°1 RMN, n°1 RX + n°1 ECO",
        presence: false,
      },

      {
        module: "Modulo I",
        destination: "Interventistica",
        mq: 556,
        postiLetto: null,
        consistency: 4,
        note: "Sale",
        presence: true,
      },
      {
        module: "Modulo I",
        destination: "mensa/bar ristorante",
        mq: 556,
        postiLetto: null,
        consistency: null,
        note: null,
        presence: false,
      },
      {
        module: "Modulo I",
        destination: "Fisiochinesiterapia + palestre",
        mq: 556,
        postiLetto: null,
        consistency: null,
        note: null,
        presence: false,
      },
      {
        module: "Modulo I",
        destination: "studi medici",
        mq: 556,
        postiLetto: null,
        consistency: null,
        note: null,
        presence: false,
      },
      {
        module: "Modulo I",
        destination: "ambulatori specialistici",
        mq: 556,
        postiLetto: null,
        consistency: null,
        note: null,
        presence: false,
      },

      {
        module: "Modulo C",
        destination: "Ambulatori specifici",
        mq: 1234,
        postiLetto: null,
        consistency: 20,
        note: "Ambulatori",
        presence: true,
      },
      {
        module: "Modulo C",
        destination: "Degenza 20pl",
        mq: 1234,
        postiLetto: 20,
        consistency: null,
        note: null,
        presence: false,
      },

      {
        module: "Modulo B",
        destination: "Ambulatori chirurgici/endoscopia",
        mq: 527,
        postiLetto: null,
        consistency: 4,
        note: "sale",
        presence: true,
      },
      {
        module: "Modulo B",
        destination: "Day surgery",
        mq: 377,
        postiLetto: 16,
        consistency: null,
        note: null,
        presence: true,
      },

      {
        module: "Modulo A",
        destination: "Day service",
        mq: 1263,
        postiLetto: 28,
        consistency: null,
        note: "pl 28 posti di cui: 20+8 oncologia",
        presence: true,
      },
      {
        module: "Modulo A",
        destination: "degenza 20pl",
        mq: 1263,
        postiLetto: 20,
        consistency: null,
        note: null,
        presence: false,
      },

      {
        module: "Modulo E",
        destination: "Hall",
        mq: 1158,
        postiLetto: null,
        consistency: "",
        note: "",
        presence: true,
      },
      {
        module: "Modulo E",
        destination: "Reception",
        mq: 61,
        postiLetto: null,
        consistency: "",
        note: "",
        presence: true,
      },

      {
        module: "Modulo D",
        destination: "Culto",
        mq: 190,
        postiLetto: null,
        consistency: "",
        note: "",
        presence: true,
      },
      {
        module: "Modulo D",
        destination: "Attese",
        mq: 109,
        postiLetto: null,
        consistency: "",
        note: "",
        presence: true,
      },
      {
        module: "Modulo D",
        destination: "Servizi generali/bar ristorante",
        mq: 502,
        postiLetto: null,
        consistency: "",
        note: "",
        presence: true,
      },
    ],
    livello_1: [
      {
        module: "Modulo E",
        destination: "Hall",
        mq: "475,00",
        postiLetto: null,
        consistency: null,
        note: null,
        presence: true,
      },
      {
        module: "Modulo D",
        destination: "servizi generali",
        mq: "429,00",
        postiLetto: null,
        consistency: null,
        note: null,
        presence: true,
      },
      {
        module: "Modulo D",
        destination: "Caffetteria",
        mq: "213,00",
        postiLetto: null,
        consistency: null,
        note: null,
        presence: true,
      },
      {
        module: "Modulo D",
        destination: "Blocco parto + Neonatologia",
        mq: "438,00",
        postiLetto: 6,
        consistency: 4,
        note: "6pl (2 TIN +4 neonatologia) +4 sale di cui n°1 per cesareo",
        presence: true,
      },
      {
        module: "Modulo A",
        destination: "ostetricia- degenza",
        mq: "675,00",
        postiLetto: 20,
        consistency: null,
        note: null,
        presence: true,
      },
      {
        module: "Modulo C",
        destination: "pediatria - degenza",
        mq: "675,00",
        postiLetto: 15,
        consistency: null,
        note: null,
        presence: true,
      },
    ],
    livello_2: [
      {
        module: "Modulo E",
        destination: "Hall + connettivo",
        mq: "1683,00",
        postiLetto: null,
        consistency: null,
        note: null,
        presence: true,
      },
      {
        module: "Modulo D",
        destination: "supporto degenza + attese",
        mq: "670,00",
        postiLetto: null,
        consistency: null,
        note: null,
        presence: true,
      },
      {
        module: "Modulo A",
        destination: "degenza 38pl",
        mq: "807,00",
        postiLetto: 38,
        consistency: null,
        note: null,
        presence: true,
      },
      {
        module: "Modulo B",
        destination: "degenza 38pl",
        mq: "807,00",
        postiLetto: 38,
        consistency: null,
        note: null,
        presence: true,
      },
      {
        module: "Modulo C",
        destination: "degenza 38pl",
        mq: "807,00",
        postiLetto: 38,
        consistency: null,
        note: null,
        presence: true,
      },
    ],
    livello_3: [
      {
        module: "Modulo E",
        destination: "Hall + connettivo",
        mq: "1683,00",
        postiLetto: null,
        consistency: null,
        note: null,
        presence: true,
      },
      {
        module: "Modulo D",
        destination: "supporto degenza + attese",
        mq: "670,00",
        postiLetto: null,
        consistency: null,
        note: null,
        presence: true,
      },
      {
        module: "Modulo A",
        destination: "degenza 38pl",
        mq: "807,00",
        postiLetto: 38,
        consistency: null,
        note: null,
        presence: true,
      },
      {
        module: "Modulo B",
        destination: "degenza 38pl",
        mq: "807,00",
        postiLetto: 38,
        consistency: null,
        note: null,
        presence: true,
      },
      {
        module: "Modulo C",
        destination: "degenza 38pl",
        mq: "807,00",
        postiLetto: 38,
        consistency: null,
        note: null,
        presence: true,
      },
    ],
    livello_4: [
      {
        module: "Modulo E",
        destination: "hall + connettivo",
        mq: "428,00",
        postiLetto: null,
        consistency: null,
        note: null,
        presence: true,
      },
      {
        module: "Modulo B",
        destination: "amministrazione",
        mq: "286,00",
        postiLetto: null,
        consistency: null,
        note: null,
        presence: true,
      },
      {
        module: "Modulo A",
        destination: "studi medici",
        mq: "280,00",
        postiLetto: null,
        consistency: null,
        note: null,
        presence: true,
      },
      {
        module: "Modulo C",
        destination: "studi medici",
        mq: "280,00",
        postiLetto: null,
        consistency: null,
        note: null,
        presence: true,
      },
    ],
    aree_esterne: [
      {
        module: "Modulo R1",
        destination: "lv-0 parcheggi a rasoterreno",
        mq: "486,00",
        postiLetto: null,
        consistency: 20,
        note: "stalli",
        presence: true,
      },
      {
        module: "Modulo R2",
        destination: "lv-0 parcheggi a rasoterreno",
        mq: "486,00",
        postiLetto: null,
        consistency: 20,
        note: "stalli",
        presence: true,
      },
      {
        module: "Modulo R3",
        destination: "lv-0 parcheggi a rasoterreno",
        mq: "6990,00",
        postiLetto: null,
        consistency: 190,
        note: "stalli",
        presence: true,
      },
      {
        module: "Modulo R4",
        destination: "lv-0 elisoccorso",
        mq: "2616",
        postiLetto: null,
        consistency: null,
        note: null,
        presence: true,
      },
    ]
  },
  projectKpiObject: {
    livello: "-1",
    mqLivello: "16.970",
    postiLettoLivello: "0",
    mqTotali: "50.038,06",
    postiLettoTotali: "434",
    costoTotale: "106.329.924,56 €",
  },
  projects: [
    {
      name: "Ospedale di Careggi",
      description: "nuovo ospedale per acuti",
      type: "acuti",
      lastEditDate: new Date() - 1,
      createdAt: new Date() - 100,
      totalCost: 18000000,
      id: 1,
    },
    {
      name: "Ospedale di Torre Galli",
      description: "Edificio per la riabilitazione",
      type: "acuti",
      lastEditDate: new Date() - 2,
      createdAt: new Date() - 12,
      totalCost: 269000000,
      id: 2,
    },
    {
      name: "Ospedale di Torre Galli",
      description: "Edificio per la riabilitazione",
      type: "acuti",
      lastEditDate: new Date() - 2,
      createdAt: new Date() - 12,
      totalCost: 269000000,
      id: 3,
    },
  ]
}
