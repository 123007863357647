<template>
  <div class="cursor-pointer" @click="toggle">
    <el-card class="border-2" :class="{'border-blue-500' : isSelected && selected}">
      <div class="flex flex-col justify-center items-center w-full">
        <div>
          <el-image
              style="width: 220px; height: 220px"
              :src="template.image"
              fit="cover"/>
        </div>
        <div class="ml-2 w-full flex flex-col text-center overflow-hidden">
          <span class="font-bold text-xl">{{template.title}}</span>
          <span class="text-xs mt-4 text-left" v-html="template.description"/>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
  export default {
    name: "templateCard",
    props: {
      template: {
        type: Object,
        default: () => {
        }
      },
      selected: {
        type: Boolean,
        default: false
      }
    },
    data() {
       return {
         isSelected: false
       }
    } ,
    methods: {
      toggle() {
        this.isSelected = true
        this.$emit('click', this.template.id)
      }
    }
  }
</script>

<style scoped>

</style>
