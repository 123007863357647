import Vue from 'vue';

const confirmHub = new Vue();

function _openConfirm(options, callback, param1, param2, title, message, catchCallback) {
	confirmHub.$confirm(message, title, options)
			.then(() => {
				callback(param1, param2);
			})
			.catch(() => {
				catchCallback();
			});
}

export default {
	openSuccessConfirm(callback = () => {}, param1 = null, param2 = null, title = 'Continuare?', message = 'Sei sicuro di confermare?', catchCallback = () => {}) {
		const options = {
			confirmButtonText: 'OK',
			cancelButtonText: 'Annulla',
			type: 'success',
			confirmButtonClass: 'el-button--success',
		};
		return _openConfirm(options, callback, param1, param2, title, message, catchCallback);
	},
	openWarningConfirm(callback = () => {}, param1 = null, param2 = null, title = 'Attenzione!', message = 'Attenzione l\'operazione è irreversibile. Continuare?', catchCallback = () => {}) {
		const options = {
			confirmButtonText: 'OK',
			cancelButtonText: 'Annulla',
			type: 'warning',
			confirmButtonClass: 'el-button--warning',
		};
		return _openConfirm(options, callback, param1, param2, title, message, catchCallback);
	},
	openErrorConfirm(callback = () => {}, param1 = null, param2 = null, title = 'Errore!', message = 'Si è verificato un errore. Riprovare?', catchCallback = () => {}) {
		const options = {
			confirmButtonText: 'OK',
			cancelButtonText: 'Annulla',
			type: 'error',
			confirmButtonClass: 'el-button--danger',
		};
		return _openConfirm(options, callback, param1, param2, title, message, catchCallback);
	},
	openInfoConfirm(callback = () => {}, param1 = null, param2 = null, title = 'Info title', message = 'Messaggio info. Continuare?', catchCallback = () => {}) {
		const options = {
			confirmButtonText: 'OK',
			cancelButtonText: 'Annulla',
			type: 'info',
			confirmButtonClass: 'el-button--info',
		};
		return _openConfirm(options, callback, param1, param2, title, message, catchCallback);
	},
};
